import React, { ReactChild, ReactChildren } from "react";
import Title from '@/components/Title/Title';

interface ColProps {
  size?: string
  className?: string
  children: ReactChild[] | ReactChildren[] | ReactChildren | ReactChildren[]
  sal?: string
  delay?: string
  duration?: string
  easing?: string
}
const Col = ({
  size,
  className,
  children,
  sal,
  delay,
  duration,
  easing
} : ColProps) => {

  return (
    <div className={`col col__${size} ${className}`} data-sal={sal} data-sal-duration={duration} data-sal-delay={delay} data-sal-easing={easing}>
      {children}
    </div>
  )
};

export default Col;
